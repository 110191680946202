import styled, { css } from 'styled-components';
import { BusinessListCard } from '../../components/BusinessListCard/BusinessListCard';
import { useEffect, useState } from 'react';
import LeafletMap from '../../components/LeafletMap/LeafletMap';
import { BannerItem, CompanyListItem, CompanySearchFilters, Topic } from '../../types/api';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getSearchCompanies } from '../../services/company.service';
import { Pagination } from '../../components/Pagination/Pagination';
import { useSelector } from 'react-redux';
import { IState } from '../../store';
import { AdCard } from '../../components/AdCard/AdCard';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AdditionalFilters } from '../../components/SearchBar/AdditionalFilters/AdditionalFilters';
import { InputItem } from '../../components/SearchBar/InputItem';
import { ReactComponent as GreenButton } from '../../assets/images/Buttons/green_inner_button.svg';
import { ReactComponent as GreenHoverButton } from '../../assets/images/Buttons/green_hover_button.svg';
import { getSearchCompaniesList } from '../../services/company.service';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { RelatedCities } from '../../components/Categories/RelatedCities';

const BusinessListPage = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
`;

const CardContainer = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 1254px;
        padding: 0 16px;
        gap: 2%;
        ${!props.theme.screen.desktop &&
        css`
            grid-template-columns: 64.5% auto;
            gap: 16px;
        `}
    `}
`;

const Hero = styled.div`
    ${(props) => css`
        width: 59%;
        min-height: calc(100vh - 523px);
        margin-bottom: 32px;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;
const SideBar = styled.div`
    ${(props) => css`
        width: 39%;
        &.hide {
            display: none;
        }
        &.mobile-size {
            .leaflet-container {
                height: 454px;
            }
        }

        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
            order: 1;
        `}
    `}
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const AdsWrapper = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        margin-bottom: 30px;
        border-radius: 8px;
        background-color: ${props.theme.colors.greyBg};
        ${!props.theme.screen.desktop &&
        css`
            border-radius: 0;
            display: flex;
            justify-content: center;
        `}
    `}
`;

const AdCardStyled = styled(AdCard)`
    flex: 1;
`;

const AdCardWrapper = styled.div`
    padding: 16px;
    ${(props) => css`
        ${props.theme.screen.desktop &&
        css`
            flex: 1;
            &:not(:last-child) {
                border-right: none;
            }
            &:not(:first-child) {
                border-left: none;
            }
        `}
    `}
`;

const NoCompaniesText = styled.div`
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
`;

const Wrap = styled.div`
    ${(props) => css`
        position: sticky;
        top: 269px;
        /* This might be needed when gate is not active. */
        /* top: 75px; */
        /* width: 42.3vw; */
        ${!props.theme.screen.desktop &&
        css`
            margin-bottom: 16px;
            width: 100%;
        `}
    `}
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
`;
const FilterWrap = styled.div``;
const FilterButton = styled.div`
    ${(props) =>
        css`
            padding: 15px 24px;
            color: ${props.theme.colors.brandRed};
            border: 1px solid ${props.theme.colors.brandRed};
            border-radius: 8px;
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
        `}
`;
const ListMapWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const ListMapButton = styled.div`
    ${(props) =>
        css`
            color: ${props.theme.colors.secondaryBlack};
            font-size: 11px;
            font-weight: 500;
            line-height: 13px;
            padding: 8px;
            text-transform: uppercase;
            cursor: pointer;
            &.active {
                color: ${props.theme.colors.green};
                &:after {
                    content: '';
                    height: 2px;
                    display: flex;
                    width: 100%;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    top: 4px;
                    background-color: ${(props) => props.theme.colors.green};
                }
            }
        `}
`;

const HeaderWrapper = styled.div`
    display: flex;
    margin-top: 16px;
    justify-content: center;
    width: 1254px;
    justify-content: space-between;
`;

const Header = styled.h1`
    margin: 0;
    font-family: 'Albert-Regular';
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    padding: 0 16px;
`;

const InputWrap = styled.form`
    ${(props) => css`
        width: 353px;
        display: flex;
        &.press-releases {
            width: 504px;
        }
        ${!props.theme.screen.desktop &&
        css`
            margin-bottom: 16px;
            width: 100%;
        `}
    `}
`;

const Button = styled.button`
    ${(props) =>
        css`
            &.input {
                width: 67px;
                height: 56px;
                background-color: ${props.theme.colors.white};
                border-radius: 0 8px 8px 0;
                border: 1px solid ${props.theme.colors.green};
                border-left: none;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            &.multi-select {
                font-size: 15px;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 0.005em;
                padding: 13px 0;
                border-radius: 8px;
                border: 1px solid ${props.theme.colors.black};
                cursor: pointer;
                transition: 0.2s ease-in-out;
                &.multi-select-delete {
                    margin-top: 15px;
                    background-color: ${props.theme.colors.white};
                }
                &.multi-select-filter {
                    background-color: ${props.theme.colors.darkGreen};
                    color: ${props.theme.colors.white};
                    z-index: 9999999;
                }
            }
        `}
`;

const OpenFilterButton = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${props.theme.colors.black};
        background-color: ${props.theme.colors.green};
        border-radius: 10px;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        margin-top: 8px;
        padding: 12px 0;
        width: 165px;
        cursor: pointer;
        &.clear {
            border: 1px solid ${props.theme.colors.darkGreen};
            background-color: ${props.theme.colors.white};
            color: ${props.theme.colors.darkGreen};
            width: 135px;
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
        &:hover {
            font-weight: 700;
        }
        ${props.theme.screen.mobile &&
        css`
            padding: 15px 0;
            width: 157px !important;
            margin-top: 0;
            &:first-child {
                order: 1;
            }
        `}
    `}
`;

export const BusinessList = () => {
    const urlParams = useParams();
    const [t] = useTranslation();
    const { state } = useLocation();
    const [companies, setCompanies] = useState<CompanyListItem[]>([]);
    const [shownCoordinates, setShownCoordiantes] = useState<CompanyListItem>();
    const { screenViewPort, bannersList, bannersFilteredByTopic } = useSelector((state: IState) => state.global);
    const [listMapToggle, setListMapToggle] = useState<{ list: boolean; map: boolean }>({ list: true, map: false });
    const [searchInput, setSearchInput] = useState<string>('');
    const [hover, setHover] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [showOnDesktop, setShowOnDesktop] = useState(false);
    const { searchFilters } = useSelector((state: IState) => state.company);
    const { topic } = useSelector((state: IState) => state.page);
    const [city, setCity] = useState<string>('');

    useEffect(() => {
        if (state?.companies) {
            return;
        }
        const filters: CompanySearchFilters = {};
        if (urlParams.categoryId && !urlParams.topicId) {
            filters.category = urlParams.categoryId;
        }
        if (urlParams.categoryId && urlParams.topicId && !urlParams.subtopicId) {
            filters.topic = urlParams.topicId;
        }
        if (urlParams.subtopicId) {
            filters.head = urlParams.subtopicId;
        }

        if (urlParams.city) {
            if (searchFilters.city.length > 0) {
                setCity(searchFilters.city[Number(urlParams.city) - 1].city);
            }
            filters.city = urlParams.city;
        }

        getSearchCompanies(1, filters).then((res) => {
            setCompanies(res.data);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams?.topicId, urlParams?.categoryId, urlParams?.subtopicId, urlParams.city]);

    useEffect(() => {
        if (state?.companies) {
            setCompanies(state.companies);
        }
    }, [state?.companies]);

    const cardsInView = (card: CompanyListItem) => {
        setShownCoordiantes(card);
    };

    const bannersSliceByIndex = () => {
        const newSlice = (array: BannerItem[], ...banner: BannerItem[]) => {
            if (array.length < 6) {
                return [...array, ...banner];
            }
            return array;
        };
        let currentSlice: BannerItem[] = [];
        if (bannersList.WBQ.length > 0) {
            currentSlice.push(bannersList.WBQ[0]);
        }
        if (urlParams?.topicId && bannersFilteredByTopic.WBS.length > 0) {
            currentSlice.push(...bannersFilteredByTopic.WBS.slice(0, 5));
        }

        if (bannersList.WBM.length > 0) {
            currentSlice = newSlice(currentSlice, ...bannersList.WBM.slice(0, 3));
        }

        if (bannersList.WBC.length > 0) {
            currentSlice = newSlice(currentSlice, bannersList.WBC[0]);
        }
        if (bannersList.WBD.length > 0) {
            currentSlice = newSlice(currentSlice, bannersList.WBD[0]);
        }

        return currentSlice;
    };

    const insertBanners = (index: number) => {
        if (screenViewPort === 'desktop') {
            return index === 0 || index === 3;
        }
        return index === 0 || index === 3 || index === 6 || index === 9 || index === 12 || index === 15;
    };

    const bannersSlice = (index: number) => {
        if (screenViewPort === 'desktop') {
            return bannersSliceByIndex().slice(index, index + 3);
        }
        switch (index) {
            case 0:
                return bannersSliceByIndex().slice(0, 1);
            case 3:
                return bannersSliceByIndex().slice(1, 2);
            case 6:
                return bannersSliceByIndex().slice(2, 3);
            case 9:
                return bannersSliceByIndex().slice(3, 4);
            case 12:
                return bannersSliceByIndex().slice(4, 5);
            case 15:
                return bannersSliceByIndex().slice(5, 6);
            default:
                return [];
        }
    };

    const capitalize = (str: string) => {
        if (str) {
            const string = str.toLowerCase();
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        return;
    };

    const buildFilters = (query: string) => {
        let additionalFilter = { key: '', value: '' };

        if (urlParams.categoryId && !urlParams.topicId) {
            additionalFilter = { key: 'category', value: urlParams.categoryId };
        }
        if (urlParams.categoryId && urlParams.topicId && !urlParams.headId) {
            additionalFilter = { key: 'topic', value: urlParams.topicId };
        }
        if (urlParams.headId) {
            additionalFilter = { key: 'head', value: urlParams.headId };
        }

        return {
            query: query || '',
            [additionalFilter.key]: additionalFilter.value,
        };
    };

    const searchForCompanies = (query: string) => {
        getSearchCompaniesList(1, buildFilters(query)).then((res) => {
            const state = { companies: res.data };
            navigate(location.pathname, { state });
        });
    };

    return (
        <>
            <BusinessListPage>
                <Helmet>
                    <title>
                        {topic.name} {city}
                    </title>
                </Helmet>
                <HeaderWrapper>
                    <Header>
                        {capitalize(topic.name)} {city}
                    </Header>
                    {screenViewPort !== 'mobile' && (
                        <OpenFilterButton
                            className="clear"
                            onClick={() => setShowOnDesktop((showOnDesktop) => !showOnDesktop)}
                        >
                            {t('filter_companies')}
                        </OpenFilterButton>
                    )}
                    <InputWrap
                        onSubmit={(e) => {
                            e.preventDefault();
                            searchForCompanies(searchInput);
                        }}
                    >
                        <InputItem
                            id="article_search"
                            class="green"
                            placeholder={t('enter_a_search_word')}
                            value={searchInput}
                            onChange={setSearchInput}
                        />
                        <Button
                            type="submit"
                            className="input"
                            onChange={(event) => event.preventDefault()}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            {!hover ? <GreenButton /> : <GreenHoverButton />}
                        </Button>
                    </InputWrap>
                </HeaderWrapper>
            </BusinessListPage>
            <BusinessListPage>
                <CardContainer>
                    {screenViewPort !== 'desktop' && (
                        <ButtonContainer>
                            <FilterWrap>
                                <AdditionalFilters query={searchInput} showOnDesktop={showOnDesktop} />
                            </FilterWrap>
                            <ListMapWrap>
                                <ListMapButton
                                    className={classNames({
                                        active: listMapToggle.list,
                                    })}
                                    onClick={() => setListMapToggle({ list: true, map: false })}
                                >
                                    {t('list')}
                                </ListMapButton>
                                <ListMapButton
                                    className={classNames({
                                        active: listMapToggle.map,
                                    })}
                                    onClick={() => setListMapToggle({ list: false, map: true })}
                                >
                                    {t('map')}
                                </ListMapButton>
                            </ListMapWrap>
                        </ButtonContainer>
                    )}
                    {screenViewPort === 'desktop' && (
                        <AdditionalFilters query={searchInput} showOnDesktop={showOnDesktop} />
                    )}
                    {(screenViewPort === 'desktop' || listMapToggle.list) && (
                        <Hero>
                            {companies.length > 0 ? (
                                <>
                                    {companies.map((company, index) => (
                                        <Wrapper key={index}>
                                            <BusinessListCard
                                                key={index}
                                                company={company}
                                                setCardInView={cardsInView}
                                            />
                                            {insertBanners(index) && bannersSliceByIndex().length > 0 && (
                                                <AdsWrapper>
                                                    {bannersSlice(index).map((banner) => {
                                                        return (
                                                            <AdCardWrapper>
                                                                <AdCardStyled
                                                                    key={banner.id}
                                                                    adUrl={banner.image}
                                                                    adHref={banner.url}
                                                                    customDimensions={{
                                                                        height: '232px',
                                                                        width: '100%',
                                                                    }}
                                                                />
                                                            </AdCardWrapper>
                                                        );
                                                    })}
                                                </AdsWrapper>
                                            )}
                                        </Wrapper>
                                    ))}
                                    <Pagination setCompanies={setCompanies} />
                                </>
                            ) : (
                                <NoCompaniesText>{t('no_companies_found')}</NoCompaniesText>
                            )}
                        </Hero>
                    )}
                    <>
                        <SideBar
                            className={classNames(
                                { hide: !listMapToggle.map && screenViewPort !== 'desktop' },
                                { 'mobile-size': screenViewPort !== 'desktop' }
                            )}
                        >
                            <Wrap>
                                <LeafletMap
                                    coordinates={screenViewPort === 'desktop' ? shownCoordinates?.address : undefined}
                                    companies={
                                        screenViewPort !== 'desktop' && listMapToggle.map ? companies : undefined
                                    }
                                />
                            </Wrap>
                        </SideBar>
                    </>
                    {urlParams.topicId && urlParams.categoryId && (
                        <RelatedCities
                            category={urlParams.categoryId}
                            topic={urlParams.topicId}
                            cities={searchFilters.city}
                        />
                    )}
                </CardContainer>
            </BusinessListPage>
        </>
    );
};
