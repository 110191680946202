import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';

import globalReducer, { IGlobalState } from './global.slice';
import languageReducer from './language/language.slice';
import companyReducer from './company/company.slice';
import userReducer from './user/user.slice';
import pageReducer from './page/page.slice';

import { ILanguageState } from './language/types';
import { ICompaniesState } from './company/types';
import { IUserState } from './user/types';
import { IPageState } from './page/types';

export interface IState {
    global: IGlobalState;
    language: ILanguageState;
    company: ICompaniesState;
    user: IUserState;
    page: IPageState;
}

const reducer = combineReducers({
    global: globalReducer,
    language: languageReducer,
    company: companyReducer,
    user: userReducer,
    page: pageReducer,
});

export const store = configureStore({
    reducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
