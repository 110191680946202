import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from './assets/styles/theme';
import { IState } from './store';
import { setBannersList, setScreenViewPort, switchBannerPositions } from './store/global.slice';
import { setCategories } from './store/company/company.slice';
import { getCategoriesList } from './services/company.service';
import { getBanners } from './services/banners.service';

import { ROUTE_NAMES } from './constants/routes';
import { Header } from './components/Header/Header';
import { EuProjectsPage } from './pages/EuProjectsPage/EuProjectsPage';
import { MainPageLayout } from './pages/MainPageLayout/MainPageLayout';
import { LandingPage } from './pages/LandingPage/LandingPage';
import { PageNotFound } from './pages/PageNotFound/PageNotFound';
import { ArticlesPage } from './pages/ArticlesPage/ArticlesPage';
import { getSearchFilters } from './services/company.service';
import { setSearchFilters } from './store/company/company.slice';
import { ArticleInner } from './components/ArticleInner/Articleinner';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { AllCategories } from './pages/AllCategories/AllCategories';
import { InnerCompanyPage } from './pages/InnerCompanyPage/InnerCompanyPage';
import { BusinessList } from './pages/BusinessList/BusinessList';
import { MediaPage } from './pages/MediaPage/MediaPage';
import CookieConsent from 'react-cookie-consent';
import { MediaInner } from './components/MediaInner/MediaInner';
import { useTranslation } from 'react-i18next';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { getUserInformation } from './services/auth.service';
import { setUser } from './store/user/user.slice';
import storage from './commons/localStorage';
// @ts-ignore
import { Helmet } from 'react-helmet';

const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const App = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { screenViewPort, bannersList } = useSelector((state: IState) => state.global);

    useEffect(() => {
        getUserInformation().then(
            (res) => {
                dispatch(setUser(res.data));
            },
            () => {
                storage.removeTokenData();
                dispatch(setUser(null));
            }
        );
        getSearchFilters().then((res) => {
            dispatch(setSearchFilters(res));
        });
        getBanners().then((response) => {
            dispatch(setBannersList(response));
        });
        getCategoriesList().then((res) => {
            dispatch(setCategories(res));
        });
        calculateViewPort();
        window.addEventListener('resize', calculateViewPort);
        return () => {
            window.removeEventListener('resize', calculateViewPort);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const bannerSwitchInterval = setInterval(() => {
            dispatch(switchBannerPositions());
        }, 10000);
        return () => {
            clearInterval(bannerSwitchInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersList]);

    const calculateViewPort = () => {
        // outerWidth doens work when you open a header link in a new window. InnerWidth solves this problem.
        const windowWidth: number = window.innerWidth;
        if (windowWidth >= 768 && windowWidth < 1024) {
            dispatch(setScreenViewPort('tablet'));
            return;
        }
        if (windowWidth < 768) {
            dispatch(setScreenViewPort('mobile'));
            return;
        }
        if (windowWidth >= 1024) {
            dispatch(setScreenViewPort('desktop'));
        }
    };

    return (
        <ThemeProvider theme={{ ...theme, screen: { [screenViewPort]: true } }}>
            <Helmet>
                <title>Visa Lietuva</title>
            </Helmet>
            <AppWrapper>
                <ScrollToTop />
                <Header />
                <Routes>
                    <Route path="/linkedin" element={<LinkedInCallback />} />
                    <Route element={<MainPageLayout />}>
                        <Route path={ROUTE_NAMES.HOME} element={<LandingPage />} />
                        <Route path={ROUTE_NAMES.ARTICLES_PAGE}>
                            <Route index element={<ArticlesPage />} />
                            <Route path={ROUTE_NAMES.ARTICLE_INNER}>
                                <Route index element={<ArticleInner />} />
                                <Route path={ROUTE_NAMES.BUSINESS_LIST_CHILD}>
                                    <Route index element={<BusinessList />} />
                                    <Route path={ROUTE_NAMES.BUSINESS_LIST_CATEGORY}>
                                        <Route index element={<BusinessList />} />
                                        <Route path={ROUTE_NAMES.BUSINESS_LIST_TOPIC}>
                                            <Route index element={<BusinessList />} />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path={ROUTE_NAMES.COMPANY_CARD} element={<InnerCompanyPage />} />
                        <Route path={ROUTE_NAMES.MEDIA_PAGE}>
                            <Route index element={<MediaPage />} />
                            <Route path={ROUTE_NAMES.MEDIA_INNER} element={<MediaInner />} />
                        </Route>
                        <Route path={ROUTE_NAMES.EU_PROJECTS} element={<EuProjectsPage />} />
                        <Route path={ROUTE_NAMES.ALL_CATEGORIES}>
                            <Route index element={<AllCategories />} />
                            <Route path={ROUTE_NAMES.BUSINESS_LIST_CHILD}>
                                <Route index element={<BusinessList />} />
                                <Route path={ROUTE_NAMES.BUSINESS_LIST_CATEGORY}>
                                    <Route index element={<BusinessList />} />
                                    <Route path={ROUTE_NAMES.BUSINESS_LIST_TOPIC}>
                                        <Route index element={<BusinessList />} />
                                        <Route path={ROUTE_NAMES.BUSINESS_LIST_SUBTOPIC}>
                                            <Route index element={<BusinessList />} />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path={ROUTE_NAMES.BUSINESS_LIST}>
                            <Route index element={<BusinessList />} />
                            <Route path={ROUTE_NAMES.BUSINESS_LIST_CATEGORY}>
                                <Route index element={<BusinessList />} />
                                <Route path={ROUTE_NAMES.BUSINESS_LIST_TOPIC}>
                                    <Route index element={<BusinessList />} />
                                    <Route path={ROUTE_NAMES.BUSINESS_LIST_CITY}>
                                        <Route index element={<BusinessList />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                    <Route path={ROUTE_NAMES.PAGE_NOT_FOUND} element={<PageNotFound />} />
                </Routes>
                <CookieConsent
                    style={{ background: '#11302D' }}
                    buttonText={t('accept')}
                    buttonStyle={{ background: '#54B77A' }}
                >
                    {t('cookie_consent')}
                </CookieConsent>
            </AppWrapper>
        </ThemeProvider>
    );
};
