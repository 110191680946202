import { Outlet, matchRoutes, useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Footer } from '../../components/Footer/Footer';
import styled, { css } from 'styled-components';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import { HorizontalBanner } from '../../components/HorizontalBanner/HorizontalBanner';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../store';
import { AdGate } from '../../components/AdGate/AdGate';
import { useEffect } from 'react';
import { setBannersByTopic, setGateIsActive, setBannersByKeyword } from '../../store/global.slice';
import { ROUTE_NAMES, routes } from '../../constants/routes';
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
import { Backdrop, CircularProgress } from '@mui/material';
import { setTopic } from '../../store/page/page.slice';
import { getTopic } from '../../services/categories.service';

const Page = styled.div`
    ${(props) => css`
        margin-top: 96px;
        min-height: calc(100vh - 307px);
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 72px;
            min-height: calc(100vh - 396px);
        `}
    `}
`;

export const MainPageLayout = () => {
    const { screenViewPort, gateIsActive, loading, bannersList, bannersFilteredByTopic, bannersFilteredByKeyword } =
        useSelector((state: IState) => state.global);
    const location = useLocation();
    const dispatch = useDispatch();
    const urlParams = useParams();
    const { state } = useLocation();

    useEffect(() => {
        if (
            (bannersFilteredByTopic.W001.length > 0 && bannersList.W001[0].image.includes('.png')) ||
            (bannersFilteredByKeyword.W003.length > 0 && bannersList.W003[0].image.includes('.png')) ||
            (bannersList.W005.length > 0 && bannersList.W005[0].image.includes('.png'))
        ) {
            dispatch(setGateIsActive(true));
            return;
        }

        dispatch(setGateIsActive(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersFilteredByTopic.W001, bannersFilteredByKeyword.W003, bannersList.W005]);

    useEffect(() => {
        dispatch(setBannersByTopic(urlParams?.topicId));

        if (urlParams.topicId) {
            getTopic(urlParams.topicId).then((response) => {
                dispatch(setTopic(response));
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersList, urlParams?.topicId]);

    useEffect(() => {
        dispatch(setBannersByKeyword(state?.filters?.query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.filters]);

    const matchRoute = () => {
        const match = matchRoutes(allowedGateRoutes, location);
        return !!match;
    };

    const allowedGateRoutes = routes.filter((route) => !route.disableGate);

    const isGateAllowed = gateIsActive && screenViewPort === 'desktop' && matchRoute();

    return (
        <>
            <Box>
                <SearchBar />
                <Page>
                    {loading && (
                        <Backdrop sx={{ color: '#fff', zIndex: 999 }} open={loading}>
                            <CircularProgress sx={{ color: 'rgb(84, 183, 122)', zIndex: 1000 }} />
                        </Backdrop>
                    )}
                    {isGateAllowed && <AdGate />}
                    {screenViewPort === 'desktop' && <HorizontalBanner isGateAllowed={isGateAllowed} />}
                    {location.pathname !== ROUTE_NAMES.HOME && <Breadcrumbs />}
                    <Outlet />
                </Page>
                <Footer />
            </Box>
        </>
    );
};
