import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { CompanyResponse } from '../../../../types/api';
import { CompanyCodes } from './components/CompanyCodes';
import { Description } from './components/Description';
import { Manager } from './components/Manager';
import { Reviews } from './components/Reviews';
import { WorkingHours } from './components/WorkingHours';
import { BankAccounts } from './components/BankAccounts';
import { useSelector } from 'react-redux';
import { IState } from '../../../../store';
import classNames from 'classnames';
import { RelatedHeads } from './components/RelatedHeads';

const InformationContainer = styled.div`
    max-width: 1254px;
    display: flex;
    flex-direction: column;
    margin: 0 0 32px;
`;

const Header = styled.div`
    ${(props) => css`
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
        margin: 24px 0 16px 0;
        scroll-margin-top: 120px;
        &.gate-active {
            scroll-margin-top: 270px;
        }
        ${!props.theme.screen.desktop &&
        css`
            &.gate-active {
                scroll-margin-top: 130px;
            }
            scroll-margin-top: 80px;
        `}
        ${props.theme.screen.mobile &&
        css`
            font-size: 18px;
            line-height: 22px;
        `}
    `}
`;
const InformationWrap = styled.div``;
const HoursReviewManager = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: 100%;
            gap: 0;
        `}
    `}
`;
const Wrap = styled.div`
    ${(props) => css`
        margin-top: 24px;
        box-shadow: 0px 4px 15px 0px ${props.theme.colors.shadow};
        height: fit-content;
        border-radius: 0 0 8px 8px;
        ${props.theme.screen.mobile &&
        css`
            display: grid;
            grid-template-columns: 40% 60%;
            border-radius: 8px;
        `}
    `}
`;

export const Information = (props: CompanyResponse) => {
    console.log(props.data.activities);
    const { gateIsActive } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();

    return (
        <InformationContainer>
            <Header id="information" className={classNames({ 'gate-active': gateIsActive })}>
                {t('information')}
            </Header>
            <InformationWrap>
                <CompanyCodes data={props.data} />
                <Description data={props.data} />
                {props.data.activities.length > 0 && <RelatedHeads heads={props.data.activities} />}
                <HoursReviewManager>
                    <Wrap>
                        <WorkingHours time={props.data.workingTime} />
                        <Reviews data={props.data} />
                    </Wrap>
                    <Manager data={props.data} />
                </HoursReviewManager>
                <BankAccounts bankAccounts={props.data.bankAccounts} />
            </InformationWrap>
        </InformationContainer>
    );
};
