import styled, { css } from 'styled-components';

import { ArticlesPreview } from '../../types/api';
import eastIcon from '@mui/icons-material/East';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IState } from '../../store';
import { AdCard } from '../AdCard/AdCard';
import classNames from 'classnames';
import { ArticlesYouMightLikeCard } from './ArticlesYouMightLikeCard';

const Container = styled.div`
    ${(props) => css`
        padding: 32px 0 48px 0;
        border-top: 1px solid ${props.theme.colors.outlineGrey};
        display: flex;
        justify-content: center;
        width: 100%;
    `}
`;
const Wrap = styled.div`
    width: 1254px;
    padding: 0 16px;
`;
const HeaderButtonWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
`;

const Head = styled.div`
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    &.page-component {
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
    }
`;
const Button = styled(Link)`
    ${(props) => css`
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding: 15px 23px;
        border: 1px solid ${props.theme.colors.darkGreen};
        border-radius: 8px;
        text-decoration: none;
        color: ${props.theme.colors.darkGreen};
        &:hover {
            color: ${props.theme.colors.green};
            border-color: ${props.theme.colors.green};
        }
    `}
`;
const EastIcon = styled(eastIcon)`
    margin-left: 8px;
`;
const Cards = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 30px;
        row-gap: 24px;
        ${!props.theme.screen.desktop &&
        css`
            grid-template-columns: repeat(2, 1fr);
            column-gap: 16px;
            display: flex;
            flex-wrap: wrap;
        `}
    `}
`;
const NoInformation = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 16px;
`;

const AdWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.colors.greyBg};
    border-radius: 8px;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            height: unset;
            width: auto;
            padding: 16px 39px;
        `}
`;
const ExpandedRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RowWrapper = styled.div`
    display: flex;
    height: 100%;
`;

const ArticleAdWrap = styled.div`
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            width: calc(50% - 8px);
            &.banner {
                width: 100%;
            }
        `}
    `}
`;

type Props = {
    articles: ArticlesPreview[] | undefined;
    youMayAlsoLike?: boolean;
};

export const ArticlesYouMightLike = (props: Props) => {
    const { t } = useTranslation();
    const { screenViewPort, bannersList } = useSelector((state: IState) => state.global);

    const articlesAndBanners = () => {
        const articlesAndBannersArray = [];
        const articleLength = props.articles ? props.articles.length : 0;
        if (props.articles) {
            for (let i = 0; i < articleLength; i++) {
                if (i < 7) {
                    articlesAndBannersArray.push(props.articles![i]);
                    if (bannersList.WBM.length > 0 && (i === 1 || (articleLength < 3 && articleLength === i + 1))) {
                        articlesAndBannersArray.push(bannersList.WBC[0]);
                    }
                    if (bannersList.WBM.length > 1 && (i === 3 || (articleLength < 3 && articleLength === i + 1))) {
                        articlesAndBannersArray.push(bannersList.WBD[0]);
                    }
                }
            }
        }
        return articlesAndBannersArray;
    };

    return (
        <>
            <Container>
                <Wrap>
                    <HeaderButtonWrap>
                        {props.youMayAlsoLike ? (
                            <Head>{t('you_may_also_like')}</Head>
                        ) : (
                            <Head>{t('new_articles')}</Head>
                        )}
                        {screenViewPort === 'desktop' && (
                            <Button to={`/straipsniai`}>
                                {t('all_articles')}
                                <EastIcon fontSize="small" />
                            </Button>
                        )}
                    </HeaderButtonWrap>
                    {articlesAndBanners() && articlesAndBanners().length > 0 ? (
                        <Cards>
                            {articlesAndBanners().map((item, index) => {
                                return (
                                    <ArticleAdWrap
                                        key={index}
                                        className={classNames({ banner: item && 'image' in item })}
                                    >
                                        <RowWrapper>
                                            {item && 'image' in item ? (
                                                <ExpandedRow>
                                                    <AdWrapper>
                                                        <AdCard
                                                            adUrl={item.image}
                                                            adHref={item.url}
                                                            customDimensions={{ height: 250, width: 250 }}
                                                        />
                                                    </AdWrapper>
                                                </ExpandedRow>
                                            ) : (
                                                <ArticlesYouMightLikeCard {...item} />
                                            )}
                                        </RowWrapper>
                                    </ArticleAdWrap>
                                );
                            })}
                        </Cards>
                    ) : (
                        <NoInformation>{t('no_information')}</NoInformation>
                    )}
                </Wrap>
            </Container>
        </>
    );
};
